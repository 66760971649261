import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import { AuthContexteProvicer, useAuthState } from "../config/firebase";

const AuthenticatedRoute = ({ component: C, ...props }) => {
  const { isAuthenticated } = useAuthState();
  //console.log(`AuthenticatedRoute: ${isAuthenticated}`);
  return (
    <Route
      {...props}
      render={(routeProps) =>
        isAuthenticated ? <C {...routeProps} /> : <Redirect to="/login" />
      }
    />
  );
};

const UnauthenticatedRoute = ({ component: C, ...props }) => {
  const { isAuthenticated } = useAuthState();
  //console.log(`UnauthenticatedRoute: ${isAuthenticated}`);
  return (
    <Route
      {...props}
      render={(routeProps) =>
        !isAuthenticated ? <C {...routeProps} /> : <Redirect to="/" />
      }
    />
  );
};

function App() {
  return (
    <AuthContexteProvicer>
      <Router>
        <Switch>
          <UnauthenticatedRoute exact path="/login" component={Login} />
          <AuthenticatedRoute exact path="/" component={Home} />
          <Route path="*">
            <h1>Page Not Found</h1>
          </Route>
        </Switch>
      </Router>
    </AuthContexteProvicer>
  );
}

export default App;
