import { useState, useRef, useCallback } from "react";
import { Form, Button } from "react-bootstrap";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const emailRef = useRef();
  const passwordRef = useRef();

  const handleSignup = useCallback(async (e) => {
    e.preventDefault();
    setErrors(false);
    setLoading(true);
    const auth = getAuth();
    try {
      await createUserWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passwordRef.current.value
      );
    } catch (e) {
      setErrors(true);
      setLoading(false);
    }
  }, []);

  const handleLogin = useCallback(async (e) => {
    e.preventDefault();
    setErrors(false);
    setLoading(true);
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passwordRef.current.value
      );
    } catch (e) {
      setErrors(true);
      setLoading(false);
    }
  }, []);

  return (
    <div className="login">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-5 col-xl-4">
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <span>Loteo Colombres</span>
                  <small>Consula de registros</small>
                </div>
              </div>
              <div className="card-body">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" ref={emailRef} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control type="password" ref={passwordRef} />
                  </Form.Group>

                  <div className="d-flex align-items-center justify-content-between">
                    <Button
                      disabled={loading}
                      variant="primary"
                      onClick={handleSignup}
                      className="d-none"
                    >
                      Signup
                    </Button>
                    <Button
                      disabled={loading}
                      variant="primary"
                      onClick={handleLogin}
                    >
                      Login
                    </Button>
                  </div>
                  {errors && (
                    <div className="mt-2">
                      <small className="text-danger">
                        Los datos ingresado son incorrectos
                      </small>
                    </div>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
