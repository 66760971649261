import React from "react";
import Moment from "react-moment";
import "moment-timezone";

export default function RegisterDetail({ dni, register }) {
  const data = register;
  const members = data.application.persons;

  return (
    <>
      <div className="alert alert-success">
        Se encontró <b>1</b> resultado
      </div>
      <div className="card  mb-3">
        <div className="card-header">
          <b>DNI Consultado</b>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <span className="registered">{dni}</span>
          </li>
        </ul>
      </div>
      <div className="card mb-3">
        <div className="card-header">
          <b>Fecha Registro</b>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <Moment format="DD/MM/YYYY" tz="America/Argentina/Cordoba">
              {data.application.created_at}
            </Moment>
          </li>
        </ul>
      </div>
      <div className="card mb-3">
        <div className="card-header">
          <b>Datos del Titular</b>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            Nombre: {data.application.applicant.fname}{" "}
            {data.application.applicant.lname}
          </li>
          <li className="list-group-item">
            <span
              className={
                dni === data.application.applicant.dni ? "registered" : ""
              }
            >
              DNI: {data.application.applicant.dni}
            </span>
          </li>
          <li className="list-group-item">
            Teléfono: {data.application.applicant.phone}
          </li>
          <li className="list-group-item">
            Email: {data.application.applicant.email}
          </li>
        </ul>
      </div>
      <div className="card mb-3">
        <div className="card-header">
          <b>Todo Grupo Familiar</b>
        </div>
        <ul className="list-group list-group-flush">
          {members.map((member, index) => (
            <li className="list-group-item" key={index}>
              <span
                className={
                  String(member.dni) === String(dni) ? "registered" : ""
                }
              >
                {member.fname} {member.lname} - DNI: {member.dni}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
