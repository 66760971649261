import React from "react";

export const Spinner = () => {
  return (
    <div className="d-flex align-items-center mb-1">
      <div
        className="spinner-grow spinner-grow-sm text-secondary"
        role="status"
      >
        <span className="visually-hidden">Buscando...</span>
      </div>
      <div className="ms-1 text-muted"> Buscando...</div>
    </div>
  );
};
