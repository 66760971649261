const api = process.env.REACT_APP_API_URL;

export default function getRegisterByDNI(dni = "") {
  return fetch(api + "/api/persons/dni/" + dni, {
    headers: {
      apitoken: process.env.REACT_APP_API_ACCESS_TOKEN,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      //console.log(res.data);
      return res.data;
    });
}
