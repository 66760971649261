import { useState } from "react";
import { Redirect } from "react-router-dom";
import RegisterDetail from "../components/RegisterDetail";
import { Spinner } from "../components/Spinner";
import getRegisterByDNI from "../services/getRegisterByDNI";
import { getAuth, signOut } from "firebase/auth";

export default function Home() {
  const [data, setData] = useState({
    dni: "",
  });
  const [dni, setDni] = useState("");
  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState({});
  const auth = getAuth();
  const title = process.env.REACT_APP_TITLE
    ? process.env.REACT_APP_TITLE
    : "LOTEO GOB. RIO CUARTO";

  const handleChange = (e) => {
    setData({
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (data.dni !== "") {
      setRegister({});
      setLoading(true);
      try {
        getRegisterByDNI(data.dni).then((res) => {
          console.log(res);

          if (res) {
            setRegister(res);
            setDni(res.dni);
          }

          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleCleanInput = () => {
    setData({ dni: "" });
    setRegister({});
    setLoading(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      return <Redirect to="/login" />;
    } catch (e) {
      alert(e.message);
    }
  };

  // console.log(dni);

  return (
    <div className="container">
      <div className="row justify-content-center mb-4">
        <div className="col-lg-12 col-xl-9">
          <div className="d-flex align-items-center justify-content-between">
            <h1>{title}</h1>
            <button className="btn btn-primary" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mb-3">
        <div className="col-lg-6 col-xl-4">
          <div className="card mb-3">
            <div className="card-body">
              <h4 className="text-uppercase mb-3">Consulta de Registros</h4>
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    type="text"
                    name="dni"
                    onChange={handleChange}
                    value={data.dni}
                    placeholder="Ingresa el DNI"
                    required
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <button className="btn btn-primary" type="submit">
                    Consultar
                  </button>
                  <button
                    className="btn btn-outline-dark"
                    onClick={handleCleanInput}
                  >
                    Limpiar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-5">
          <div className="card">
            <div className="card-body">
              <h4 className="text-uppercase mb-3">Resultado</h4>
              {loading && <Spinner />}
              {register.dni ? (
                <RegisterDetail dni={dni} register={register} />
              ) : (
                !loading && <div>No se encontraron registros</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
